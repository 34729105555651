import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useRouter } from 'next/router';

import { Product } from 'api/generated-types';

import templates from './templates';

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement(MutableRefObject, boolean);
    };
  }
}

interface Props {
  template: keyof typeof templates;
  theme?: 'light' | 'dark';
  product?: Product;
  height?: string;
  className?: string;
}

const TrustBox: React.FC<Props> = ({
  template: templateKey,
  theme = 'light',
  product,
  height,
  className,
}) => {
  const ref = React.useRef(null);
  const { locale } = useRouter();
  const template = templates[templateKey];

  let skuList = null;
  if (product) {
    const productEanCodes = product.variants.map((variant) => variant.identifiers.eanCode);
    const productUniversalKeys = product.variants.map((variant) => variant.universalKey);

    skuList = [...productEanCodes, ...productUniversalKeys];
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }

    const appendScript = () => {
      const isScriptAdded = document.getElementById('trustpilot-sdk');
      if (!isScriptAdded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.id = 'trustpilot-sdk';
        document.head.appendChild(script);
      }
    };

    appendScript();
  }, []);

  return (
    <>
      <div
        ref={ref}
        data-locale={locale}
        data-review-languages={locale}
        data-theme={theme}
        data-sku={skuList}
        data-template-id={template.id}
        data-style-height={height ?? template.height}
        data-businessunit-id="5b8ff708b1dcc80001154fa8"
        data-style-width="100%"
        data-fullwidth="true"
        data-font-family="Roboto"
        className={classNames('trustpilot-widget', className)}
      >
        <a
          href="https://www.trustpilot.com/review/edgardcooper.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};
export default TrustBox;
