const templates = {
  'micro-combo': {
    id: '5419b6ffb0d04a076446a9af',
    height: '24px',
  },
  'micro-star': {
    id: '5419b732fbfb950b10de65e5',
    height: '22px',
  },
  'product-mini-multi-source': {
    id: '577258fb31f02306e4e3aaf9',
    height: '24px',
  },
  'products-reviews-multi-source-seo': {
    id: '5763bccae0a06d08e809ecbb',
    height: '512px',
  },
  'products-reviews-multi-source': {
    id: '57177697fdb1180308e3815f',
    height: '512px',
  },
  mini: {
    id: '53aa8807dec7e10d38f59f32',
    height: '120px',
  },
  'product-reviews': {
    id: '544a426205dc0a09088833c6',
    height: '700px',
  },
};

export default templates;
