import { ECImage, ReferenceNumber } from 'components/ui';
import { ImageSetKeys, useImages } from 'context/ImagesProvider';
import { useTranslations } from 'services/translations/TranslationsProvider';

interface Props {
  v2?: boolean;
}

const Benefits = ({ v2 }: Props): JSX.Element => {
  const { t } = useTranslations('content.benefits');
  const { img } = useImages(ImageSetKeys.ContentBenefits);

  const benefits = [
    {
      image: img('optimalDigestionV2'),
      title: t('optimalDigestion'),
      description: t('optimalDigestionDescription'),
    },
    {
      image: img('healthySkinAndCoatV2'),
      title: t('healthySkinAndCoat'),
      description: t('healthySkinAndCoatDescription'),
    },
    {
      image: img('naturalImmunityV2'),
      title: t('naturalImmunity'),
      description: t('naturalImmunityDescription'),
    },
  ];

  /**
   * V2 benefits for the BE home page
   */
  if (v2) {
    const benefitsV2 = [
      {
        image: img('optimalDigestionV3'),
        title: t('optimalDigestion'),
        description: t('optimalDigestionDescription'),
        color: '#F5C7EF',
      },
      {
        image: img('healthySkinAndCoatV3'),
        title: t('healthySkinAndCoat'),
        description: t('healthySkinAndCoatDescription'),
        color: '#FCDEAB',
      },
      {
        image: img('naturalImmunityV3'),
        title: t('naturalImmunity'),
        description: t('naturalImmunityDescription'),
        color: '#8DD9D7',
      },
    ];

    return (
      <div className="text-purple-800 font-rooney text-lg">
        <div className="container">
          <div className="mb-12">
            <h3 className="text-2.5xl sm:text-3xl font-rodetta flex flex-col gap-0 justify-center items-center mb-4 text-center max-w-sm mx-auto leading-none text-balance">
              {t('benefitsTitle')}
            </h3>
            <p className="font-rooney text-center text-purple-800 font-normal text-sm mx-auto max-w-copy opacity-70">
              {t('benefitsIntroduction')}
              <ReferenceNumber>1</ReferenceNumber>
            </p>
          </div>
          <div className="grid grid-cols-3 flex-gap-12">
            {benefitsV2.map((benefit) => (
              <div className="col-span-3 md:col-span-1" key={benefit.image?.src}>
                <div className="mx-auto flex md:flex-col md:items-center max-w-sm flex-nowrap gap-3">
                  <ECImage
                    img={benefit.image}
                    className="min-w-[2.5rem] max-w-[4rem] md:min-w-[5rem] md:max-w-[6rem] xl:max-w-[7rem] transform -translate-y-[37%] md:transform-none"
                  />
                  <div className="md:text-center">
                    <h4 className="text-lg font-rodetta">
                      <span
                        className="px-1.5 py-0.5 box-decoration-clone rounded"
                        style={{ backgroundColor: benefit.color }}
                      >
                        {benefit.title}
                      </span>
                    </h4>
                    <p className="mt-3 text-base font-normal">{benefit.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Default benefits
   */
  return (
    <div className="text-purple-700 font-rooney text-lg">
      <div className="container">
        <h3 className="text-3xl md:text-3.5xl text-purple-700 font-rodetta flex flex-col gap-0 justify-center items-center mb-12 text-center leading-none">
          {t('benefitsTitleLine1')}
          <br />
          {t('benefitsTitleLine2')}
        </h3>
        <div className="grid grid-cols-3 flex-gap-12">
          {benefits.map((benefit) => (
            <div className="col-span-3 md:col-span-1" key={benefit.image?.src}>
              <div className="mx-auto flex md:flex-col md:items-center max-w-sm flex-nowrap gap-3">
                <ECImage
                  img={benefit.image}
                  className="min-w-[2.5rem] max-w-[4rem] md:min-w-[5rem] md:max-w-[6rem] xl:max-w-[7rem] mb-2 transform -translate-y-[20%] md:transform-none"
                />
                <div className="md:text-center">
                  <h4 className="text-lg lg:text-xl font-bold leading-tight self-center">
                    {benefit.title}
                  </h4>
                  <p className="mt-2 text-base text-gray-400 font-normal">{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
