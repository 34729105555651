/* eslint-disable max-len */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';

import classNames from 'classnames';
import SwiperType from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Product } from 'api/generated-types';
import { ECImage, Icon, ReferenceNumber } from 'components/ui';
import { Testimonial } from 'services/contentful/generated-types';
import { useTranslations } from 'services/translations/TranslationsProvider';
import useViewport from 'utils/hooks/useViewport';

interface Props {
  product?: Product;
  testimonials?: Testimonial[];
  // New color
  v2?: boolean;
}

const Testimonials = ({ product, testimonials, v2 }: Props): JSX.Element => {
  const { t, tReplaceStrong } = useTranslations('content.testimonials');
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const { isMobile } = useViewport();

  if (!testimonials || testimonials.length < 4) {
    return <></>;
  }

  return (
    <div className="overflow-hidden">
      <div className="container">
        {/* heading */}
        {!product && (
          <h3
            className={classNames(
              'mx-auto leading-none font-rodetta [&>strong]:font-normal [&>strong]:whitespace-nowrap text-center mb-12 text-balance',
              v2
                ? 'max-w-sm text-purple-800 [&>strong]:text-3xl sm:[&>strong]:text-3.5xl text-2.5xl sm:text-3xl'
                : 'max-w-copy text-purple-700 [&>strong]:text-purple-550 text-3xl md:text-3.5xl',
            )}
          >
            {v2 ? tReplaceStrong('testimonialsTitleV2') : tReplaceStrong('testimonialsTitle')}
            {v2 ? <ReferenceNumber>2</ReferenceNumber> : ''}
          </h3>
        )}
        {/* carousel of testimonial cards */}
        <div className="w-full relative z-0">
          <Swiper
            onSwiper={(newSwiper) => {
              setSwiper(newSwiper);
              setIsBeginning(newSwiper.isBeginning);
              setIsEnd(newSwiper.isEnd);
            }}
            onRealIndexChange={(s) => {
              setIsBeginning(s.isBeginning);
              setIsEnd(s.isEnd);
            }}
            slidesPerView={1.2}
            spaceBetween={16}
            style={{
              paddingBottom: 2,
              overflow: 'visible',
            }}
            breakpoints={{
              400: { slidesPerView: 1.2, spaceBetween: 16 },
              556: { slidesPerView: 2.2, spaceBetween: 24 },
              832: { slidesPerView: 3.2, spaceBetween: 24 },
              1024: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
            className="px-4 max-w"
          >
            {/* Testimonials are decided at build time through an algorithm in the getStaticProps */}
            {testimonials.map((testimonial) => (
              <React.Fragment key={testimonial.sys.id}>
                <SwiperSlide
                  key={testimonial.sys.id}
                  style={{
                    userSelect: 'none',
                    width: 400,
                    height: 'auto',
                  }}
                >
                  <div className="bg-white flex flex-col items-center justify-center font-rooney text-base text-gray-500 rounded-2xl overflow-hidden relative h-full shadow-sm">
                    <ECImage
                      img={{
                        src: testimonial.image.image.url,
                        alt: testimonial.image.image.description,
                      }}
                      className="object-cover absolute inset-0"
                      pictureClassName="aspect-w-5 aspect-h-4 relative w-full"
                    />
                    <div className="p-4 lg:p-6 flex flex-col grow w-full">
                      <p className="flex flex-col grow">{testimonial.description}</p>
                      <div className="flex flex-col gap-y-2 mt-4 lg:mt-6">
                        <div className="flex gap-0.5">
                          {Array.from({ length: testimonial.rating }).map((_, index) => (
                            <Icon
                              name="star-mini"
                              // Without index key, React will throw duplicate key warning
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${testimonial.author}-${testimonial.sys.id}-${index}`}
                              className="text-green-trustpilot w-4 h-4"
                            />
                          ))}
                        </div>
                        <p className="text-base font-medium">— {testimonial.author}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </React.Fragment>
            ))}
          </Swiper>
          {/* navigation buttons for larger screens */}
          <button
            type="button"
            onClick={() => swiper?.slidePrev(200)}
            className={classNames(
              'maxMd:hidden absolute top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 z-10 rounded-full transition-all',
              isBeginning ? 'opacity-0' : 'opacity-100',
              v2
                ? 'bg-purple-800 hover:bg-purple-750 active:purple-750 focus:bg-purple-750'
                : 'bg-purple-700 hover:bg-purple-600 active:bg-purple-600 focus:bg-purple-600',
            )}
            hidden={isMobile}
          >
            <Icon
              name="chevron-left"
              className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white"
            />
          </button>
          <button
            type="button"
            onClick={() => swiper?.slideNext(200)}
            className={classNames(
              'maxMd:hidden absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 w-12 h-12 z-10 rounded-full transition-all',
              isEnd ? 'opacity-0' : 'opacity-100',
              v2
                ? 'bg-purple-800 hover:bg-purple-750 active:purple-750 focus:bg-purple-750'
                : 'bg-purple-700 hover:bg-purple-600 active:bg-purple-600 focus:bg-purple-600',
            )}
            hidden={isMobile}
          >
            <Icon
              name="chevron-right"
              className="w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white"
            />
          </button>
        </div>
        {/* note */}
        {!product && !v2 && (
          <p
            className={classNames(
              'font-rooney text-sm text-center font-medium mt-6',
              v2 ? 'text-purple-800' : 'text-purple-700',
            )}
          >
            {t('testimonialsNote')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
